:root {
  --primary: #FF6060;
  --gray-1: #F7F7F7;
  --gray-2: #ccc;
  --linear-gradient:linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
  --font-family: Art , sans-serif;
  --font-family2: BEBAS NEUE, sans-serif;
  --text-color: #444;
  --bounce-animation: bounce 2s;
}

