.header {
  //height: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;

  .menu {
    z-index: 1;
    color: #e74c3c;
    font-size: 2.6rem;
    opacity: 0.9;
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #222;

    li {
      margin: 1rem;
    }
    
    @media screen and (min-width: 992px) {
      display: none;
      height: 0;
    }
  }


  @media screen and (min-width: 992px) {
    display: block;
    padding: 0;
    font-family: var(--font-family);
    font-size: 1.2rem;
    width: 100%;
    position: relative;
    background: #222;
    color: white;
  }
  font-family: var(--font-family);
  font-size: 1.2rem;
  width: 100%;
  position: relative;
  background: #222;
  color: white;

  ul {
    display: none;
    @media screen and (min-width: 992px) {
      flex: 1;
      //height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}




.links {
  width: 95%;
  height: 80px;
  margin: 0 auto;
  display: flex;

  li {

    @media screen and (min-width: 992px) {
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }


    a {
      display: none;
      @media screen and (min-width: 992px) {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        transition: background 0.3s, color 0.3s;
        &:hover {
          color: #222;
          background: white;
        }
      }
    }
  }
}


.isActive {
  color: #222 !important;;
  background: white;
}

.isActiveMenu {
  color: white !important;
}

.hamburger {
  font-size: 2.6rem;
  cursor: pointer;
  transition: transform 0.4s;

  &:hover {
    transform: scale(1.5);
  }

  @media screen and (min-width: 992px) {
    display: none;
  }
}

.hamburgerMenuLinks {
  font-weight: bold;
}



