.main {
  background: #222;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

 img {
   height: 300px;

   @media screen and (min-width: 768px) {
     height: 450px;
   }@media screen and (min-width: 992px) {
     height: 600px;
   }
 }

  p{
    color: white;
    font-size: 1.2rem;
    margin: 1rem 0;
    @media screen and (min-width: 768px) {
      font-size: 1.6rem;
    }
  }
  a {
    text-decoration: underline;
    color: white;
    font-size: 1rem;
    @media screen and (min-width: 768px) {
      font-size: 1.4rem;
    }
    &:hover {
      color: #FF6060;
    }
  }
}