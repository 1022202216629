

.main {
  flex: 1;
  height: 100vh;
  background: #333;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 3rem;
  @media screen and (min-width: 768px ) {
    padding: 5rem;
  }

  @media screen and (min-width: 992px) {
    padding: 4rem 4rem 0 4rem;
    /*flex-direction: row;*/
    align-items: center;
    justify-content: space-around;


  }
  h1 {
    text-align: center;
    font-family: var(--font-family2);
    font-weight: bold;
    font-size: 3rem;
    @media screen and (min-width: 992px){
      font-size: 4rem;
    }
  }


  .links {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    gap: 1rem;
    @media screen and (min-width: 992px) {
      gap: 0.2rem;
      justify-content: center;
    }


    a {

      @media screen and (min-width: 992px) {
        margin: 3rem;
      }

    }

    img {
      height: 80px;
      width: 80px;
      transition: transform 1.2s;

      &:hover {
        transform: scale(1.2);
      }

      @media screen and (min-width: 768px ) {
        height: 130px;
        width: 130px;
      }

      @media screen and (min-width: 992px){
        height: 135px;
        width: 135px;
      }
    }
  }
}