.card {
  height: 100%;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.2rem;
  background: #F2E5D9;
  border-radius: 5px;
  /*margin-bottom: 2rem;*/

  @media screen and (min-width: 992px) {
    transition: transform 0.8s;
    /*height: 600px;*/
    &:hover {
      transform: scale(1.1);

    }
  }


  img {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 200px;
    width: 100%;
    object-fit: cover;

    @media screen and (min-width: 768px){
      height: 280px;
    }


    @media screen and (min-width: 992px) {
    height: 300px;
    }
  }



  h2 {
    font-weight: bold;
    margin: 1rem;
    font-size: 1.6rem;
  }

  p {
    margin: 0 1rem 1rem 1rem;
    font-size:1.2rem;
  }
}

//carte pas symetrique lorsque la largeur de l'ecran -