.portfolio {
  h1 {
    text-align: center;
    font-family: var(--font-family2);
    font-weight: bold;
    font-size: 4rem;
    @media screen and (min-width: 768px) {
      font-size: 6rem;

    }
    @media screen and (min-width: 992px){
      /*font-size: 4rem;*/
      font-size: 8rem;
      margin: 4rem 0;
    }
  }
  color: white;
  background: #222;
  padding: 0 2rem 2rem 2rem;

  @media screen and (min-width: 768px){
    /*padding: 4rem;*/
  }

  h2 {
    font-family: var(--font-family2);
    font-size: 2rem;

    @media screen and (min-width: 768px) {
      font-size: 2.5rem;
    }
  }

}

.cardContainer {
  gap: 2.4rem;
  display: grid;
  grid-template-columns: 1fr;
  @media screen and (min-width: 992px) {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 4rem;
  }
}
