@keyframes bounce {
  0% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(-35deg);
  }
  70% {
    transform: rotate(20deg);
  }
  80% {
    transform: rotate(-10deg);
  }
  90% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}