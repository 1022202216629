

.main {
  font-size: 1.2rem;
  background:#EBEBEB;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  

  

  .title {
    font-family: var(--font-family2);
    font-size: 4rem;
    font-weight: bold;
    text-align: center;
    grid-area: title;
    margin: 1.6rem 0;
    @media screen and (min-width: 768px) {
      margin: 4rem 0;
      font-size: 6rem;
    }
    @media screen and (min-width: 992px) {
      /*font-size: 4rem;*/
      font-size: 8rem;
    }
  }

   section {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 1.6rem;
   }
  }

  