.main {
  padding: 0 3rem 2rem 3rem;
  background: #222;
  color: white;


  @media screen and (min-width: 992px) {
    /*padding: 4.5rem;*/
  }

  h1 {

    font-family: var(--font-family2);
    font-weight: bold;
    text-align: center;
    font-size: 4rem;
    margin: 1.6rem 0;

    @media screen and (min-width: 768px) {
      /*font-size: 4rem;*/
      font-size: 6rem;
      margin: 4rem 0;
    }

    @media screen and (min-width: 992px) {
      /*font-size: 4rem;*/
      font-size: 8rem;
      margin: 4rem 0;
    }
  }





  section {
  display: flex;
  flex-direction: column;


    @media screen and (min-width: 992px) {
      flex-direction: row;
      align-items: center;
      &:nth-child(3) {
        flex-direction: row-reverse;
      }
    }
}

  img {
    object-fit: cover;
    margin-top: 1rem;
    align-self: center;
    border-radius: 50%;
    height: 250px;
    width: 250px;



    @media screen and (min-width: 992px) {
      margin: 2.5rem;
      height: 320px;
      width: 320px;
    }
  }


  h2 {
    font-family: var(--font-family2);
    font-weight: bold;
    position: relative;
    bottom: -25px;
    opacity: 0;
    margin: 2rem 0;
    font-size: 2.5rem;
    @media screen and (min-width: 992px) {
      font-size: 3rem;
    }
  }

  p {
    font-family: Caladea;
    position: relative;
    bottom: -25px;
    opacity: 0;
    margin-bottom: 3rem;
    font-size: 1.2rem;
    line-height: 26px;
    @media screen and (min-width: 992px) {
      font-size: 1.6rem;
      line-height: 38px;
    }
  }

 i {
color: #88dded;
font-size: 1.2rem;
}

}

.left {
  position: relative;
  left: -50px;
  opacity: 0;
}

.right {
  position: relative;
  right: -50px;
  opacity: 0;
}
