
.screen {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Art;
  background: #222;
  height: 100vh;
  color: white;

}

.art {
  z-index: 0;
  left: 50%;
  top: 48%;
  transform: translate(-50%, -50%);
  position: absolute;
  height: 250px; //250
  width: 250px; //250
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  box-shadow: 15px 15px 50px rgba(0,0,0,0.2);
  animation: morphing 10s infinite;


  @media screen and (min-width: 768px){
    height: 350px; //test !!
    width: 350px;
  }

}

  .titleContainer {
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 6rem;

    @media screen and (min-width: 992px){
      margin-bottom: 0;
    }

    h1 {
      font-size: 3.2rem;
      @media screen and (min-width: 768px) {
        font-size: 6rem;
      }
      @media screen and (min-width: 992px) {
        font-size: 9rem;

      }

      span {
        display: inline-block;
        animation: var(--bounce-animation);
        animation-delay: 5.5s;
        color: var(--primary);

      }
    }

    p {
      font-size: 1.2rem;
      margin-right: 1.2rem;
      align-self: flex-end;

      @media screen and (min-width: 768px) {
      font-size: 2rem;
        margin-right: 2.2rem;
      }

      @media screen and (min-width: 992px) {
        font-size: 2.8rem;
        margin-right: 4rem;
      }
    }
  }

.link {
  opacity: 0;
  z-index: 1;
  margin-top: 4rem;
  font-size: 2rem;
  color: var(--primary);
  bottom: 14rem;
  cursor: pointer;
  transition: opacity 0.8s, font-size 0.8s;




  @media screen and (min-width: 768px) {
    font-size: 3rem;
  }

   &:hover {
     font-size: 2.2rem;
     opacity: 0.6;

     @media screen and (min-width: 768px){
       font-size: 3.2rem;
       opacity: 0.6;
     }

   }
}
.introAnim {
  position: relative;
  top: 100px;
  opacity: 0;
}

title {
  position: relative;
  top: 100px;
  opacity: 0;
}



@keyframes morphing {
  0% {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    box-shadow: 15px 15px 50px rgba(0,0,0,0.2);
  }
  25% {
    border-radius: 58% 42% 75% 25% / 76% 46% 54% 24%;
  }
  50% {
    border-radius: 50% 50% 33% 67% / 55% 27% 73% 45%;
    box-shadow: -10px -5px 50px rgba(0,0,0,0.2);
  }
  75% {
    border-radius: 33% 67% 58% 42% / 63% 68% 32% 37%;
  }
}


