.formContainer {
  padding: 2rem;
  background: #FFFFFF; border-radius: 1rem;
  margin: 1rem 0;


  @media screen and (min-width: 992px) {
    height: 600px;
    width: 600px;
    margin: 0;
  }
}

h1 {
  font-weight: bold;
  font-size: 2rem;
  margin: 1.6rem 0;
}

.form {
  display: flex;
  flex-direction: column;
  label {
    font-weight: bold;
    font-size: 1rem;
  }

  input {
    outline: none;
    font-size: 1rem;
    border: none;
    border-bottom: 1px solid black;
    margin: 0.5rem 0 1rem 0;
    height: 30px;
  }

  textarea {
    outline: none;
    font-size: 1rem;
    border: none;
    border-bottom: 1px solid black;
    resize: vertical;
    height: 120px
  }

button {
  font-size: 1rem;
  color: white;
  background: #FF6060;
  margin-top: 2rem ;
  height: 40px;
  width: auto;
  border-radius: 5px;

  &:hover {
    background: lighten(#FF6060, 5)
  }
}

}