.footer {
  color: white;
  font-size: 0.8rem;
  height: 80px;
  margin-top: auto;
  background: #222;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}