#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  footer {
    margin-top: auto;
  }
}


body {
  margin: 0;
  padding: 0;
  font-size: 62.5%;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4 {
  margin: 0;
  font-weight: 400;
}


* {
  box-sizing: border-box;
}

ul {
  list-style: none;
  padding-left: 5px;
  margin: 0;
}

a, a:visited, a:hover, a:active {
  text-decoration: none;
  color: inherit;
}

p {
  margin: inherit;
}

button {
  border: none;
  text-decoration: none;
}
