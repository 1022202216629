.button {
  cursor: pointer;
  grid-area: button;
  color: white;
  margin: 1rem 0;
  background-color: hsl(0, 100%, 69%);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  padding: 0 20px;
  font-size: 18px;
  box-shadow: hsl(0, 74%, 61%) 0px 7px 0px 0px;
  border-radius: 12px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  animation: bn53bounce 4s infinite;

  &:hover {
    opacity: 0.6;
  }

}

@keyframes bn53bounce {
  5%,
  50% {
    transform: scale(1);
  }

  10% {
    transform: scale(1);
  }

  15% {
    transform: scale(1);
  }

  20% {
    transform: scale(1) rotate(-5deg);
  }

  25% {
    transform: scale(1) rotate(5deg);
  }

  30% {
    transform: scale(1) rotate(-3deg);
  }

  35% {
    transform: scale(1) rotate(2deg);
  }

  40% {
    transform: scale(1) rotate(0);
  }
}